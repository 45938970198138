import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { config } from './config/config';
import JoditEditor from 'jodit-react';
import { Appbar, ConfirmationDialog } from './components';
// import { useLocalStorage } from '../../hooks';
import { getHeader, getFooter, getBody } from './helpers';
import { templates, templateModalities } from './templates/templates';
import secureLocalStorage from 'react-secure-storage';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './addReport.css';
import useReportingServices from './reportingServices';
import userServices from '../User/userServices';
import { LoadingIndicatorProgress } from '@ohif/ui';
import { Button as CustomButton } from '@ohif/ui';
import useLocalStorage from './../../hooks/useLocalStorage';
import { getAiReportBody } from './helpers/getAiReportBody';
// import logo from "../../../public"
const AddReport = () => {
  const editor = useRef(null);
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [aiReport, setAiReport] = useState({});
  const [isAIReportPreview, setIsAIReportPreview] = useState(false);
  const [flag, setFlag] = useState(false);
  const [patientReport, setPatientReport] = useState();
  const [patientData, setPatientData] = useState(
    JSON.parse(secureLocalStorage.getItem('patient-info'))
  );
  const [selectedTemplate, setSelectedTemplate] = useLocalStorage('template', '');
  const [selectedModality, setSelectedModality] = useLocalStorage('modality', 'Default');
  const [openDialog, setOpenDialog] = useState(false);
  const { getStudyReport, getAIReport, addStudyReport, updateStudyReport } = useReportingServices();
  const { handleAlert } = userServices();
  const navigate = useNavigate();

  useEffect(() => {
    // Get Normal Report (Created)
    const getPatientReport = async () => {
      getStudyReport(patientData?.studyInstanceUid)
        .then(prevReport => {
          setPatientReport(prevReport);
          setIsLoading(false);
          setIsNew(false);
        })
        .catch(err => {
          setIsLoading(false);
        });
    };

    // Get AI Report for patient

    const getAiReport = async () => {
      await getAIReport(patientData?.studyInstanceUid)
        .then(report => {
          setAiReport(report);
          console.log('aiReport', report);
          setFlag(true);
        })
        .catch(error => {
          console.info('AI report Status: False');
        });
    };
    getPatientReport();
    getAiReport();
  }, [submit]);

  useEffect(() => {
    const handleUnload = e => {
      e.preventDefault();
      e.returnValue = 'Are you sure you want to leave this page?';
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  const initt =
    patientReport && !isNew
      ? patientReport?.description
      : isAIReportPreview
        ? `${getHeader(patientData, '')}<br/>${getAiReportBody(aiReport)}`
        : `${getHeader(patientData, '')}<br/>${getBody(
            selectedModality?.value,
            selectedTemplate?.id
          )}${getFooter()} `;

  // Report Submission
  const handleSubmit = async e => {
    e.preventDefault();
    let body = {
      study_id: patientData.studyInstanceUid,
      patient_id: patientData.mrn,
      patient_name: patientData.patientName,
      description: value,
    };
    await addStudyReport(patientData.studyInstanceUid, body);
    setIsNew(false);
    setSubmit(!submit);
  };

  // handle report updates
  const handleUpdate = async e => {
    e.preventDefault();
    let body = {
      description: value,
    };

    await updateStudyReport(patientData.studyInstanceUid, body);
    setIsNew(false);
    setSubmit(!submit);
    handleAlert('success', 'Updated Successfully...');
  };

  const handleBackWithSave = async e => {
    e.preventDefault();
    if (patientReport) {
      let body = {
        description: value,
      };
      await updateStudyReport(patientData?.studyInstanceUid, body);
      handleAlert('success', 'Updated Successfully...');
    } else {
      let body = {
        study_id: patientData.studyInstanceUid,
        patient_id: patientData.mrn,
        patient_name: patientData.patientName,
        description: value,
      };
      await addStudyReport(patientData.studyInstanceUid, body);
      handleAlert('success', 'Posted Successfully...');
    }
    // history.push(`/viewer/${studyData.studyInstanceUid}`);
    navigate(`/viewer?StudyInstanceUIDs=${patientData?.studyInstanceUid}`);
  };

  const handleBackWithoutSave = async e => {
    e.preventDefault();
    navigate(`/viewer?StudyInstanceUIDs=${patientData?.studyInstanceUid}`);
  };

  // Confirmation Dialogue
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getReportData = val => {
    setValue(val);
  };
  const aiReportToggle = () => {
    setIsAIReportPreview(!isAIReportPreview);
    // window.location.reload(false);
  };
  return (
    <>
      {isLoading ? (
        <div className="flex flex-col items-center justify-center pt-48">
          <LoadingIndicatorProgress className={'bg-secondary-light h-full w-full'} />
        </div>
      ) : (
        <div className="position-relative flex min-h-screen flex-col items-center bg-white ">
          <Appbar
            flag={flag}
            setFlag={setFlag}
            patientReport={patientReport}
            isNew={isNew}
            setIsNew={setIsNew}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            templateOptions={templates[selectedModality?.value]}
            templateModalities={templateModalities}
            signatures={[]}
            selectedModality={selectedModality}
            setSelectedModality={setSelectedModality}
            handleOpenDialog={handleOpenDialog}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            isAIReportPreview={isAIReportPreview}
            setIsAIReportPreview={setIsAIReportPreview}
            // selectedSignature={selectedSignature}
            // setSelectedSignature={setSelectedSignature}
          />
          {/* <img src={require('./clientLogos/AFIRI.jpg').default} /> */}
          <div className="mt-24 w-3/5 overflow-x-hidden">
            <div className="editor__container">
              <div className="editor">
                <JoditEditor
                  className="jodit_editor"
                  ref={editor}
                  value={initt}
                  config={config}
                  // tabIndex={1}
                  //   onBlur={(newContent) => getValue(newContent)}
                  onChange={newContent => getReportData(newContent)}
                />
              </div>
              {/* <div className="viewer "> */}
              {/* <h2>Viewer</h2> */}
              {/* <img src={require(`../../../public/assets/CMH.png`).default}></img> */}
              {/* {value}  */}
              {/* <div dangerouslySetInnerHTML={{ __html: value }} /> */}
              {/* </div> */}
            </div>
          </div>
          <br />
        </div>
      )}
      <Modal
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <h1 className="text-xl font-semibold text-white">Do you want to save changes?</h1>
        <p className="text-lg text-white">
          If you choose "No", the content will be deleted permanently, else the report will be
          saved.
        </p>
        <br />
        <div className="modal__btns">
          <CustomButton
            onClick={handleBackWithoutSave}
            className="bg-secondary-main p-1 text-white"
          >
            No
          </CustomButton>
          <CustomButton
            onClick={handleBackWithSave}
            className="bg-secondary-main p-1 text-white"
          >
            Yes
          </CustomButton>
        </div>
      </Modal>
    </>
  );
};

export default AddReport;
