import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useContext } from 'react';
import {
  Paper,
  Button,
  makeStyles,
  TextField,
  Box,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import './styling.css';
import { Svg } from '@ohif/ui';
import { Link, useNavigate } from 'react-router-dom';
import userServices from './userServices';
import { useAppConfig } from '../../state';
import useLocalStorage from '../../hooks/useLocalStorage';
const useStyles = makeStyles(theme => ({
  paper: {
    margin: 'auto',
    minWidth: 460,
    padding: theme.spacing(5),
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(2),
    width: '100%',
    color: '#337ab7 !important',
  },
  button: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: 0,
    backgroundColor: '#337ab7',
  },
}));

const Login: React.FC = () => {
  const classes = useStyles();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState({});

  const [todayDate, setTodayDate] = useState<number>();
  const isFormValid = username && password;

  const navigate = useNavigate();
  const { login, handleAlert, serverConfig } = userServices();
  const [appConfig, setAppConfig] = useAppConfig();
  // let enableUser = false;
  useEffect(() => {
    fetch('http://worldtimeapi.org/api/ip')
      .then(response => response.json())
      .then(data => {
        const currentDateTime = new Date(data.datetime);
        setTodayDate(() => currentDateTime.getTime());
      })
      .catch(error => {
        console.log('Error fetching date from the internet:', error);
      });
  }, []);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const expiryDate = new Date('2040-06-22');
    const generateRandomString = () => {
      const randomString = Math.random().toString();
      return randomString;
    };

    let date = new Date();
    if (expiryDate.getTime() < todayDate || expiryDate.getTime() < date.getTime()) {
      alert(date.toLocaleDateString());
      navigate('/licenseexpiry');
    } else {
      try {
        await login(username, password);
        setLoginError({});
        // await serverConfig();
      } catch (error) {
        if (error?.response?.status == '401') {
          setLoginError(() => {
            return error?.response;
          });
          handleAlert('error', 'Authentication Error.');
          error?.response?.data.attempts == 0 ? navigate('/accountblocked') : '';
        } else if (error?.response?.status == '409') {
          navigate(`/accountblocked`);
          handleAlert('error', 'Your account is temporarily blocked.');

          // setLoginError(() => {
          //   return error?.response;
          // });
        } else {
          handleAlert('error', 'Connection Failed...');
        }
        throw error;
      }
    }
  };
  return (
    <Box
      className="flex min-h-screen flex-col items-center justify-center p-8"
      style={{ minHeight: '100vh' }}
    >
      <Paper className={classes.paper}>
        <a
          target="_blank"
          className=" mb-2	grid place-items-center"
        >
          <Svg
            name="logo-xylexa"
            style={{ width: '12rem' }}
          />
        </a>
        {/* <img src='https://xylexa.ai/wp-content/uploads/2023/02/logo-white-full-1.png'/> */}
        {/* <Typography variant="h4"  style={{color:"white"}}>
        XYLEXA
      </Typography> */}
        <form
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <TextField
            id="username"
            label="Username"
            variant="outlined"
            className={classes.textField}
            value={username}
            onChange={handleUsernameChange}
          />
          <TextField
            id="password"
            label="Password"
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            className={classes.textField}
            value={password}
            onChange={handlePasswordChange}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
          />
          <div className={!loginError?.data?.detail ? 'hidden' : ' w-60  text-red-600'}>
            {loginError?.status == '401' ? (
              <p className="overflow-hidden  overflow-ellipsis">
                Invalid Password! <br />
                You have {loginError?.data?.attempts ? loginError?.data?.attempts : 4} attempts
                left.
              </p>
            ) : loginError?.status == '409' ? (
              <p className="overflow-hidden  overflow-ellipsis"> {loginError?.data?.detail} </p>
            ) : (
              ''
            )}
          </div>
          <p></p>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isFormValid}
          >
            Login
          </Button>
        </form>
        <Typography
          variant="body2"
          style={{ color: '#e0e0e0' }}
        >
          For account setup or technical queries,{' '}
          <a
            href="https://www.xylexa.ai/contact"
            target="_blank"
            rel="no-referrer"
            style={{
              color: '#32dffe',
            }}
          >
            Contact Us
          </a>
        </Typography>
      </Paper>
      <Typography
        variant="body2"
        style={{ color: '#e0e0e0' }}
      >
        XyCAD Version 3.0 | Xylexa, Inc. © 2024
      </Typography>
    </Box>
  );
};

export default Login;
