export default {
  1: { description: 'Abdomen', window: '400', level: '10' },
  2: { description: 'Brain', window: '15', level: '30' },
  3: { description: 'Brain', window: '80', level: '40' },
  4: { description: 'Brain', window: '400', level: '50' },
  5: { description: 'Brain', window: '150', level: '80' },
  6: { description: 'Bone', window: '2000', level: '500' },
  7: { description: 'Cardiac', window: '1000', level: '200' },
  8: { description: 'Cardiac', window: '500', level: '230' },
  9: { description: 'Chest', window: '500', level: '50' },
  10: { description: 'Clone', window: '1500', level: '-200' },
  11: { description: 'Liver', window: '220', level: '120' },
  12: { description: 'Liver', window: '220', level: '50' },
  13: { description: 'Lung', window: '1500', level: '-500' },
  14: { description: 'Lung', window: '800', level: '-700' },
  15: { description: 'Neck', window: '350', level: '50' },
  16: { description: 'Soft tissue', window: '400', level: '40' },
  17: { description: 'Vascular', window: '600', level: '150' },
};
