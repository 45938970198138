import React, { useContext, useState, useEffect } from 'react';
import { useUserAuthentication } from '@ohif/ui';
import { useNavigate } from 'react-router';
import secureLocalStorage from 'react-secure-storage';

export const PrivateRoute = ({ children, handleUnauthenticated }) => {
  const [{ user, enabled }, { setUser }] = useUserAuthentication();
  const navigate = useNavigate();
  useEffect(() => {
    if (!secureLocalStorage.getItem('user') || !secureLocalStorage.getItem('auth')) {
      navigate('/login');
    }
  }, []);
  return children;
};

export default PrivateRoute;
