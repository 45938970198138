import React, { ReactNode, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { NavBar, Svg, Icon, IconButton, Dropdown } from '../';
import { Select, Tooltip } from '@ohif/ui';
import userServices from '@OHIF/app/src/routes/User/userServices';
import { useAppConfig } from '@OHIF/app/src/state';
import useLocalStorage from './../../../../app/src/hooks/useLocalStorage';
import { Modal } from 'react-responsive-modal';
import secureLocalStorage from 'react-secure-storage';
import { Button as CustomButton } from '@ohif/ui';

//signal
import { effect, signal } from '@preact/signals-react';

export const orthancType = signal(secureLocalStorage.getItem('orthancType'));
effect(() => {
  secureLocalStorage.setItem('orthancType', orthancType.value);
});
function Header({
  children,
  pacsOptions,
  menuOptions,
  isReturnEnabled,
  onClickReturnButton,
  isSticky,
  WhiteLabeling,
  ...props
}): ReactNode {
  const { t } = useTranslation('Header');
  const [appConfig, setAppConfig, serverType, setServerType] = useAppConfig();

  const [dicomId, setDicomId] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const [modal, setModal] = useState(false);
  const [modalityList, setModalityList] = useState([]);
  const [modality, setModality] = useState({ id: '', value: '', label: '' });

  const [patientInfo, setPatientInfo] = useState(
    JSON.parse(secureLocalStorage.getItem('patient-info'))
  );

  const serverOptions = [
    { id: 0, value: 'local', label: 'Local' },
    { id: 1, value: 'cloud', label: 'Cloud' },
  ];
  const [slectedServer, setSlectedServer] = useLocalStorage(
    'type',
    serverOptions.find(r => r.value === 'local')
  );

  // TODO: this should be passed in as a prop instead and the react-router-dom
  // dependency should be dropped
  const services = userServices();

  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
    }
  };
  const onSelectServer = async slectedServer => {
    setSlectedServer(slectedServer);
    await services.serverConfig(slectedServer?.value);
  };

  useEffect(() => {
    const fetchStudyId = async () => {
      try {
        const studyId = JSON.parse(secureLocalStorage.getItem('patient-info'))?.studyInstanceUid;
        let a = JSON.parse(secureLocalStorage.getItem('server'));
        const auth = a.results[0].requestOptions?.auth;
        const base_url = new URL(a.results[0].wadoRoot).origin;
        setBaseUrl(base_url);
        let body = {
          Level: 'Studies',
          Query: {
            StudyInstanceUID: studyId,
          },
        };
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic ' + btoa(auth),
          },
          body: JSON.stringify(body),
        };

        const response = await fetch(base_url + '/tools/find', requestOptions);
        const data = await response.json();
        setDicomId(data[0]);
      } catch (error) {
        console.log('error', error);
      }
    };

    const getModalities = async () => {
      let a = JSON.parse(secureLocalStorage.getItem('server'));

      const auth = a.results[0].requestOptions?.auth;
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + btoa(auth),
        },
      };

      const response = await fetch(baseUrl + '/modalities', requestOptions);
      const data = await response.json();
      const required = data.reduce((result, currentValue) => {
        result.push({ key: currentValue, value: currentValue });
        return result;
      }, []);
      // console.log('data', required[0]['value']);

      const list = required.reduce((result, current) => {
        result.push({ id: current?.value, value: current?.value, label: current?.value });
        return result;
      }, []);
      setModalityList(list);
      setModality(list[0] || {});
    };
    fetchStudyId();
    // getModalities();
  }, [baseUrl]);

  async function sendToModality(dicomId, targerAET) {
    const data = {
      Resources: [dicomId],
      Synchronous: false,
      // LocalAet: targerAET,
      // MoveOriginatorAet: targerAET,
      // MoveOriginatorID: 1234,
      Timeout: 10,
      // StorageCommitment: false,
    };
    try {
      const resp = await fetch(baseUrl + '/modalities/' + targerAET + '/store', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      // console.log('res', resp.ok);
      if (resp.ok) {
        services.handleAlert('success', 'Modality Uploaded Successfully...');
      } else {
        services.handleAlert('error', 'Modality Connection Failed...');
      }
      setModal(false);
    } catch (error) {
      console.log('error', error);
      services.handleAlert('error', 'Modality Connection Failed...');
    }
  }
  const onCloseModal = () => {
    setModal(false);
  };
  const onSelectModality = async () => {
    setModality(() =>
      modalityList.find((item, key) => {
        if (item?.value === modality?.value) return item;
      })
    );
  };
  return (
    <NavBar
      className="bg-secondary-dark al px justify-between border-b-4 border-black pt-5"
      isSticky={isSticky}
    >
      <div className="flex w-4/5 flex-1 justify-between">
        <div className="flex items-center ">
          {/* // TODO: Should preserve filter/sort
              // Either injected service? Or context (like react router's `useLocation`?) */}
          <div
            className={classNames(
              'mr-3 inline-flex items-center',
              isReturnEnabled && 'cursor-pointer'
            )}
            onClick={onClickReturn}
            data-cy="return-to-work-list"
          >
            {isReturnEnabled && (
              <Icon
                name="chevron-left"
                className="text-secondary-main w-12"
              />
            )}
            <div className="">
              {WhiteLabeling?.createLogoComponentFn?.(React, props) || (
                <a
                  href="https://xylexa.ai/"
                  target="_blank"
                >
                  <Svg
                    name="logo-xylexa"
                    className="w-40 sm:w-24 md:w-32 lg:w-36"
                  />
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="justify-content-between align-content-center flex items-center  ">
          {children}
        </div>
        <div className="flex items-center gap-1">
          {!isReturnEnabled && (
            <div className="d-flex">
              <Select
                id="select-server"
                className="border-primary-main min-w-28 relative mr-3 w-28 bg-transparent text-white"
                value={orthancType.value}
                isMulti={false}
                isClearable={false}
                isSearchable={false}
                closeMenuOnSelect={true}
                hideSelectedOptions={true}
                options={serverOptions}
                onChange={onSelectServer}
              />
            </div>
          )}

          {/* <span className="mr-3 text-lg text-common-light">
            {t('Send to Modality')}
          </span> */}

          <Tooltip
            content={'Send to Modality'}
            className={isReturnEnabled ? '' : 'hidden'}
          >
            <Icon
              name="share-modality"
              onClick={() => setModal(true)}
              className="text-secondary-main h-6 w-6"
            />
          </Tooltip>
          <Dropdown
            id="options"
            alignment="right"
            showDropdownIcon={false}
            list={menuOptions}
          >
            <IconButton
              id={'options-settings-icon'}
              variant="text"
              color="inherit"
              size="initial"
              className="text-primary-active"
            >
              <Icon name="settings" />
            </IconButton>
            <IconButton
              id={'options-chevron-down-icon'}
              variant="text"
              color="inherit"
              size="initial"
              className="text-primary-active"
            >
              <Icon name="chevron-down" />
            </IconButton>
          </Dropdown>
        </div>
        {isReturnEnabled && (
          <Modal
            classNames="modal"
            open={modal}
            onClose={onCloseModal}
            style={{
              minHeight: '30vh',
              padding: '2rem',
            }}
          >
            <section
              style={{
                display: 'flex',
                flexDirection: 'column',
                color: 'white',
                minHeight: '30vh',
                minWidth: '40vw',
              }}
            >
              <h1 className="text-primary-active mx-2 text-2xl font-semibold">
                {patientInfo?.patientName}
              </h1>
              <hr />
              <div className="ga my-5 flex flex-col gap-2">
                <span style={{ fontSize: '1rem' }}>
                  PatientId: <strong>{patientInfo?.mrn}</strong>
                </span>
                <span style={{ fontSize: '1rem' }}>
                  Accession Number: <strong>{patientInfo?.accession}</strong>
                </span>
                {/* <span style={{ fontSize: '1rem' }}>
            Study Date: <strong>{patientInfo.time}</strong>
          </span> */}
                <span style={{ fontSize: '1rem' }}>
                  Study Description: <strong>{patientInfo?.description}</strong>
                </span>
              </div>
            </section>
            <hr />
            <div className="buttons">
              <Select
                id="select-modality"
                value={modality}
                onChange={onSelectModality}
                options={modalityList}
                isMulti={false}
                isClearable={false}
                isSearchable={false}
                closeMenuOnSelect={true}
                hideSelectedOptions={true}
                // closeMenuOnSelect={true}
                // placeholder="Select Modality"
              />
              <CustomButton
                className="button bg-primary-dark hover:bg-primary-active mt-6"
                onClick={() => sendToModality(dicomId, modality)}
                // style={{ marginTop: '1rem', background: '#337ab7' }}
              >
                Send To Modality
              </CustomButton>
            </div>
          </Modal>
        )}
      </div>
    </NavBar>
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.object,
};

Header.defaultProps = {
  isReturnEnabled: true,
  isSticky: false,
};

export default Header;
