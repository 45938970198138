import React, { useState } from 'react';
import { Button, IconButton, Select } from '@ohif/ui';
import { Icon } from '@ohif/ui';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { Svg } from '@ohif/ui';
import { Button as CustomButton } from '@ohif/ui';

const Appbar = ({
  flag,
  setFlag,
  isNew,
  setIsNew,
  selectedTemplate,
  setSelectedTemplate,
  templateOptions,
  templateModalities,
  signatures,
  selectedModality,
  setSelectedModality,
  selectedSignature,
  setSelectedSignature,
  handleOpenDialog,
  handleSubmit,
  handleUpdate,
  patientReport,
  isAIReportPreview,
  setIsAIReportPreview,
}) => {
  const navigate = useNavigate();

  const handleTemplateChange = (newValue: string) => {
    setSelectedTemplate(newValue);
  };
  const handleModalityChange = (newValue: string) => {
    setSelectedModality(newValue);
  };
  const handleSignatureChange = (newValue: string) => {
    setSelectedSignature(newValue);
  };
  return (
    <div className="fixed top-0 z-50 flex h-20 w-full items-center justify-between bg-black px-4 text-white sm:px-0 md:px-4">
      <div
        className={classNames('mr-3 inline-flex cursor-pointer items-center')}
        onClick={handleOpenDialog}
      >
        <Icon
          name="chevron-left"
          className="text-secondary-main w-8"
        />
        <div className="ml-6">
          <Svg
            name="logo-xylexa"
            className="md:w-30 w-36"
          />
        </div>
      </div>
      <div className="flex place-items-center">
        <Select
          id="select-template-modality"
          className={
            isNew && !isAIReportPreview
              ? 'min-w-36 border-secondary-main relative mr-3  bg-transparent text-white'
              : 'hidden'
          }
          value={selectedModality}
          isMulti={false}
          isClearable={false}
          isSearchable={false}
          closeMenuOnSelect={true}
          hideSelectedOptions={true}
          options={templateModalities}
          onChange={handleModalityChange}
          placeholder="Select Modality"
        />
        <Select
          id="select-template"
          className={
            isNew && !isAIReportPreview
              ? 'min-w-72 md:min-w-40  sm:min-w-10 relative mr-3 border-white bg-transparent text-white'
              : 'hidden'
          }
          value={selectedTemplate}
          isMulti={false}
          isClearable={false}
          isSearchable={true}
          closeMenuOnSelect={true}
          hideSelectedOptions={true}
          options={templateOptions}
          onChange={handleTemplateChange}
          placeholder="Select Template"
        />
        {/* <Select
      id="select-template-signature"
      className="relative mr-3 w-36 min-w-28 bg-transparent text-white"
      value={selectedSignature}
      isMulti={false}
      isClearable={false}
      isSearchable={true}
      closeMenuOnSelect={true}
      hideSelectedOptions={true}
      options={signatures}
      onChange={handleSignatureChange}
      placeholder="Select Signature"
    /> */}
        <div className="flex gap-3">
          {flag && isNew && (
            <CustomButton
              className="bg-secondary-main p-1 text-white"
              onClick={() => setIsAIReportPreview(!isAIReportPreview)}
            >
              {isAIReportPreview ? 'Generic Report' : 'AI Report'}
            </CustomButton>
          )}
          {patientReport?.description && (
            <CustomButton
              className={'bg-secondary-main p-1 text-white'}
              onClick={() => setIsNew(!isNew)}
            >
              {isNew ? 'Previous Report' : 'Create New'}
            </CustomButton>
          )}
          <CustomButton
            className={patientReport?.description ? 'bg-secondary-main p-1 text-white' : 'hidden'}
            onClick={handleUpdate}
          >
            Update
          </CustomButton>

          <CustomButton
            className={patientReport?.description ? ' hidden' : 'bg-secondary-main p-1 text-white'}
            onClick={handleSubmit}
          >
            Submit
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default Appbar;
