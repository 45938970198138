import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './viewReport.css';
// import { aiServices } from '../utils/aiSrevices';
import { BiMessageSquareEdit, BiArrowBack, BiPrinter } from 'react-icons/bi';
import { LoadingIndicatorProgress } from '@ohif/ui';
import useReportingServices from './reportingServices';
import secureLocalStorage from 'react-secure-storage';
import { useReactToPrint } from 'react-to-print';
import { AiFillFileAdd } from 'react-icons/ai';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const ViewReport = () => {
  const [patientReport, setPatientReport] = useState({});
  // const [studyData, setStudyData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [patientData, setPatientData] = useState(
    JSON.parse(secureLocalStorage.getItem('patient-info'))
  );
  const { getStudyReport } = useReportingServices();
  const pdfRef = useRef();

  const navigate = useNavigate();

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });
  const getPatientReport = async () => {
    getStudyReport(patientData?.studyInstanceUid)
      .then(prevReport => {
        setPatientReport(prevReport);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        // navigate(`/viewer/write-report/?StudyInstanceUIDs=${patientData?.studyInstanceUid}`);
      });
  };
  useEffect(() => {
    getPatientReport();
  }, []);

  const handleEdit = e => {
    e.preventDefault();
    navigate(`/viewer/write-report/StudyInstanceUIDs=${patientData?.studyInstanceUid}`);
  };
  const handleBack = e => {
    e.preventDefault();
    navigate(-1);
    // navigate(`/viewer?StudyInstanceUIDs=${patientData?.studyInstanceUid}`);
  };

  return (
    <>
      {isLoading ? (
        <LoadingIndicatorProgress className={'bg-secondary-light h-full w-full'} />
      ) : (
        <div className="report_container ">
          <div className="appbar">
            <a
              className="back_btn"
              onClick={handleBack}
            >
              <BiArrowBack />
              <span style={{ fontSize: '15px' }}>Back to study</span>
            </a>
            <div className="action_btns">
              <a
                className="back_btn"
                onClick={handleEdit}
              >
                <BiMessageSquareEdit />
                <span style={{ fontSize: '15px' }}>
                  {patientReport?.description ? 'Edit Report' : 'Create Report'}
                </span>
              </a>
              <a
                className="back_btn"
                onClick={handlePrint}
              >
                <BiPrinter />
                <span style={{ fontSize: '15px' }}>{'Print'}</span>
              </a>
            </div>
          </div>
          {/* <img src={require('./assets/logo1.jpg').default} alt=""></img> */}
          <div className="report__section w-3/5">
            <div
              className="report_data"
              ref={pdfRef}
            >
              {patientReport?.description ? (
                <div
                  dangerouslySetInnerHTML={{ __html: patientReport?.description }}
                  id="#report"
                />
              ) : (
                <div className="flex justify-center ">
                  <div className="align-center bg-secondary-light flex w-3/5 flex-col justify-center p-8 text-white">
                    <AiFillFileAdd className="h-20 w-20 " />

                    <h2 className="text-center text-white">Report not added yet. </h2>
                    <p className="text-center text-white">Click here to add your report...</p>
                    <button
                      className="button mt-4 "
                      onClick={handleEdit}
                    >
                      Create Report
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewReport;
