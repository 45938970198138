import axios from 'axios';
import { useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import secureLocalStorage from 'react-secure-storage';
import { useAppConfig } from '../../state';
import { orthancType } from '@ohif/ui/src/components/Header/Header';

// const api_url = 'http://103.137.25.163:8000/api/';

const api_url = 'https://backend.xylexa.ai/api/';
// const api_url = 'http://192.168.18.142:8000/api/';

function userServices() {
  const navigate = useNavigate();
  // const [appConfig, setAppConfig, serverType, setServerType] = useAppConfig();
  const [tries, setTries] = useState(0);
  // Authenticaion check
  async function isAuthenticated() {
    try {
      if (!secureLocalStorage.getItem('user') || !secureLocalStorage.getItem('auth')) {
        navigate('/login');
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  // Logout
  function logout() {
    secureLocalStorage.clear();
    // secureLocalStorage.removeItem('server');
    // secureLocalStorage.removeItem('auth');
    // secureLocalStorage.removeItem('user');
    navigate('/login');
    handleAlert('success', 'Logout Successfully');
  }

  // Login
  async function login(username, password) {
    const body = {
      username: username,
      password: password,
    };
    const response = await axios.post(`${api_url}token/`, body);
    const data = response.data;
    console.log('data', data);
    secureLocalStorage.setItem('auth', JSON.stringify(data));
    const auth = JSON.parse(secureLocalStorage.getItem('auth') || '');
    const decoded = jwt_decode(auth.access);
    const userData = await makeRequest(`v1/users/${decoded?.user_id}/`, 'get');
    secureLocalStorage.setItem('user', JSON.stringify(userData));
    handleAlert('success', 'User Authenticated Successfully');
    await serverConfig();
    return true;
  }

  async function serverConfig(pacs_type = 'cloud') {
    isAuthenticated();
    const user = JSON.parse(secureLocalStorage.getItem('user'));
    // for (let i = 0; i < user.groups.length; i++) {
    //   var group = user.groups[i].split('/');
    //   group_ids.push(group[group.length - 2]);
    // }
    var group = user.groups[0].split('/');
    let groupId = group[group.length - 2];
    makeRequest(`v1/groupprofiles/?group_id=${groupId}&pacs_type=${pacs_type}`, 'get')
      .then(data => {
        // alert(data)
        let a = document.createElement('a');
        a.href = data['results'][0]['wadoRoot'];
        let base_url = a.host;
        let protocol = a.protocol;

        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'text/plain',
            // crossorigin: true,
            // mode: 'no-cors',
            Authorization: 'Basic ' + btoa(data['results'][0].requestOptions.auth),
          },
        };
        if (pacs_type == 'cloud') {
          makeRequest(`v1/groupprofiles/?group_id=${groupId}&pacs_type=cloud`, 'get')
            .then(data => {
              secureLocalStorage.setItem('server', JSON.stringify(data));
              window.config.dataSources[0].configuration = data?.results[0];
              // setServerType({ id: 1, value: 'cloud', label: 'Cloud' });
              // console.log("server",data?.results[0])
              orthancType.value = { id: 1, value: 'cloud', label: 'Cloud' };
              handleAlert('success', 'Cloud Server Connected.');
              window.location.assign('/');
            })
            .catch(err => {
              console.log('ERROR', err);
              // setServerType({ id: 0, value: 'local', label: 'Local' });
              orthancType.value = { id: 0, value: 'local', label: 'Local' };

              handleAlert('error', 'Cloud servre not found!');
              window.location.assign('/');
            });
        } else {
          fetch(protocol + '//' + base_url + '/tools/now', requestOptions)
            .then(response => {
              response.text();
            })
            .then(result => {
              secureLocalStorage.setItem('server', JSON.stringify(data));
              window.config.dataSources[0].configuration = data?.results[0];
              // setServerType({ id: 0, value: 'local', label: 'Local' });
              orthancType.value = { id: 0, value: 'local', label: 'Local' };

              handleAlert('success', 'Local Server Connected.');
              window.location.assign('/');
            })
            .catch(error => {
              console.log('error', error);
              makeRequest('v1/groupprofiles/?group_id=' + groupId + '&pacs_type=cloud', 'get').then(
                data => {
                  secureLocalStorage.setItem('server', JSON.stringify(data));
                  window.config.dataSources[0].configuration = data?.results[0];
                  // setServerType({ id: 1, value: 'cloud', label: 'Cloud' });
                  orthancType.value = { id: 1, value: 'cloud', label: 'Cloud' };

                  handleAlert('success', 'Cloud Server Connected.');

                  window.location.assign('/');
                }
              );
            });
        }
        // data.results.map((item, key) => (servers[key] = [item]));
      })
      .catch(err => {
        console.log('error', err);
        makeRequest(`v1/groupprofiles/?group_id=${groupId}&pacs_type=cloud`, 'get')
          .then(data => {
            secureLocalStorage.setItem('server', JSON.stringify(data));
            window.config.dataSources[0].configuration = data?.results[0];
            // setServerType({ id: 1, value: 'cloud', label: 'Cloud' });
            orthancType.value = { id: 1, value: 'cloud', label: 'Cloud' };

            handleAlert('success', 'Cloud Server Connected.');
            window.location.assign('/');
          })
          .catch(err => {
            console.log('error', err);
            handleAlert('error', 'Servers not found!');
            logout();
          });
      });
  }
  // make http request
  async function makeRequest(endpoint, method, body = undefined) {
    // try {
    await refreshToken();
    let options = {
      method: method.toUpperCase(),
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json',
      },
    };

    if (body && (method.toUpperCase() === 'POST' || method.toUpperCase() === 'PATCH')) {
      options.data = JSON.stringify(body);
    }

    const response = await axios(api_url + endpoint, options);
    return response.data;
    // } catch (error) {
    //   // Toast('error', 'error');
    //   // handleAlert('error', error);
    //   throw error;
    // }
  }

  // method to check the expiry of authentication token
  async function refreshToken() {
    return new Promise((resolve, reject) => {
      if (secureLocalStorage.getItem('user') && secureLocalStorage.getItem('auth')) {
        let auth = JSON.parse(secureLocalStorage.getItem('auth'));
        const decoded = jwt_decode(auth.access);
        if (decoded.exp < Date.now() / 1000) {
          const url = api_url + 'token/refresh/';
          const options = {
            method: 'POST',
            mode: 'cors',
            cache: 'default',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refresh: auth.refresh,
            }),
          };

          var request = new Request(url);
          fetch(request, options)
            .then(handleResponse)
            .then(data => {
              auth.access = data.access;
              secureLocalStorage.setItem('auth', JSON.stringify(auth));
              resolve();
            })
            .catch(error => {
              logout();
              reject();
            });
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  // authentication header setting
  function authHeader() {
    // return authorization header with jwt token
    let auth = JSON.parse(secureLocalStorage.getItem('auth'));

    if (auth && auth.access) {
      return {
        Authorization: 'Bearer ' + auth.access,
      };
    } else {
      return {};
    }
  }

  // handling response of apis
  async function handleResponse(response) {
    try {
      const text = await response.text();
      const data = text && JSON.parse(text);

      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from API
          logout();
        }

        const error = (data && data.message) || response.statusText;
        throw error;
      }

      return data;
    } catch (error) {
      throw error;
    }
  }

  async function handleAlert(type, msg) {
    const opt = {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    };
    if (type == 'error') {
      toast.error(msg, opt);
    } else if (type == 'success') {
      toast.success(msg, opt);
    }
  }

  function setData(endpoint, method, body = undefined) {
    return new Promise((resolve, reject) => {
      refreshToken().then(() => {
        let options = {
          method: method.toUpperCase(),
          mode: 'cors',
          cache: 'default',
          headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
          },
        };
        if (
          (body && method.toUpperCase() === 'POST') ||
          (body && method.toUpperCase() === 'PATCH')
        ) {
          options.body = JSON.stringify(body);
        }
        let request = new Request(api_url + endpoint);

        fetch(request, options)
          .then(handleResponse)
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            handleAlert('error', error);
            reject(error);
          });
      });
    });
  }

  return {
    isAuthenticated,
    login,
    logout,
    makeRequest,
    serverConfig,
    handleAlert,
    setData,
  };
}

export default userServices;
